import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Layout, { Right } from "./Layout";

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { html } = markdownRemark;

  const title = markdownRemark.frontmatter.title;

  const meta = () => {
    if (markdownRemark.frontmatter.title === "Home") {
      return;
    }

    const title = `${markdownRemark.frontmatter.title} – braack`;

    return (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    );
  };

  const Head = (
    <Right>
      <h1>braack</h1>
      <p>DIPL.-ING. CHRISTIAN BRAACK</p>
    </Right>
  );

  return (
    <Layout Head={title === "Home" ? Head : undefined}>
      {meta()}
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
      html
    }
  }
`;
